import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

//run on load
  $( () => {
    // a nice lil tidbit. When you switch to 4k scaling this stops elements with a transition from scaling up weirdly.
    const transitionTimeout = () => {
      const classes = document.body.classList;
      let timer = null;

      window.addEventListener( 'resize' , () => {
        if ( timer ){
          clearTimeout( timer );
          timer = null;
        } else {
          classes.add( 'stop-transition' );
        }

        timer = setTimeout( () => {
          classes.remove( 'stop-transition' );
          timer = null;
        }, 100);

      });
    }

    transitionTimeout();

    // GSAP STUFF
    gsap.registerPlugin( SplitText, ScrollTrigger )

    let animDuration = 0.73;
    let animStart = 'top 90%';
    let toggleAction = 'play'; //didnt like how restart reverse looked

    if( $( '.split' ).length ) {
      // Fade in word-by-word for title content

      const split = document.querySelectorAll( '.split' );

      split.forEach( ( split ) => {

        let container = $( split ).parent('.container');
        let text = new SplitText( split , { type: 'words' } )
        let words = text.words;

        gsap.from( words, {
          yPercent: 100,
          stagger: 0.073,
          opacity: 0,
          ease: 'cubic-bezier( .56 , .52 , .53 , 1.25 )',
          duration: animDuration,
          
          scrollTrigger: {
            trigger: container,
            start: animStart,
            toggleActions: toggleAction,
            // markers: true
          }
        });
      });
    }

    if( $( '.fade' ).length ) {
      // Fade in for non-title content
      const fadeItem = document.querySelectorAll( '.fade' )

      fadeItem.forEach( ( item ) => {
        gsap.fromTo( 
          item, { 
            opacity: 0, 
            y: 50 
          }, {    
            opacity: 1,
            y: 0,
            duration: animDuration,

            scrollTrigger: {
              trigger: item,
              start: animStart,
              toggleActions: toggleAction,
              // markers: true
            }
          }
        );
      });
    }

    if( $( '.process-grid ' ).length ) {
      // Fade in for non-title content
      const processItem = document.querySelectorAll( '.process-items .bg-2' )

      processItem.forEach( ( item ) => {
        gsap.fromTo(
          item, { 
            opacity: 0
          }, {    
            opacity: 1,
            duration: 2.25,

            scrollTrigger: {
              trigger: item,
              start: 'top 50%',
              toggleActions: toggleAction,
              // markers: true
            }
          }
        );
      });
    }


    if( $( '.waterfall' ).length ) {
      //basic waterfall + fade
      let waterfallItems = document.querySelectorAll( '.waterfall' );
    
      waterfallItems.forEach( ( item ) => {
        gsap.fromTo( 
          $( item ).children(), {
            opacity: 0 
          }, {
            opacity: 1,
            duration: 0.325,
            stagger: 0.125,
  
            scrollTrigger: {
              trigger: item,
              start: 'top bottom-=200'
            }
          }
        )
      })
    }

    if( $( '.media-grid' ).length ) {
      let mediaGrid = $( '.media-grid' )
      let mediaImages = document.querySelectorAll( '.media-grid .image-container' )
      let mm = gsap.matchMedia()

      mm.add( '(min-width: 960px)', () => {
        // Refresh ScrollTriggers
        ScrollTrigger.refresh();

        mediaImages.forEach( ( elem, index ) => {
          if( index % 2 ) {
            gsap.to( elem, {
              y: index * -10 ,
              scrollTrigger: {
                trigger: mediaGrid,
                start: animStart,
                scrub: index,
              }
            } )
          } else {
            gsap.to( elem, {
              y: index * 20 ,
              scrollTrigger: {
                trigger: mediaGrid,
                start: animStart,
                scrub: index,
              }
            } )
          }
        } )
      } )
    }

    let footerHeight = $( '.footer' ).outerHeight()

    gsap.fromTo(
      $( '.rtt' ), { 
        opacity: 0,
      }, {    
        opacity: 1,
        duration: 0.273,

        scrollTrigger: {
          trigger: $( '#main-content' ),
          start: 'top+=50px 90%',
          end: `bottom-=${footerHeight} 100%`,
          toggleActions: 'restart reverse restart reverse',
          // markers: true
        }
      }
    );
})